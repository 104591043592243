import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
  SectionHelper,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import { useTranslation } from "react-i18next"
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "Auto refresh count every day",
  "Manual refresh count anytime you like",
  "Priority support",
];

const INTERVAL = 4000;

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [connection, setConnection] = React.useState({} as any);
  const [isRequestedRefresh, setIsRequestedRefresh] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  const isFree = instanceData?.instance?.isFree !== false;

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex: any) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/one-ins/_functions", // Replace with your API base URL
      applicationID: "face4909-6367-4611-9924-837b8ee6ae59", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, (upgradeState: boolean) => {
      setIsUpgraded(upgradeState);
      setIsUpgradeBannerOpen(!upgradeState);
    });
    getConnection();
  }, []);

  const getConnection = () => {
    fetch("https://certifiedcode.wixsite.com/one-ins/_functions/connection", {
      headers: {
        Authorization: instance!,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setConnection(res);
      });
  };

  const updateCount = () => {
    setIsRequestedRefresh(true);
    fetch("https://certifiedcode.wixsite.com/one-ins/_functions/count", {
      method: "POST",
      headers: {
        Authorization: instance!,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      });
  };

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = basePlatform.getUpgradeUrl();

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {t('upgradeMessage', { content: t(`content.${index}`) })}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title={t('ins-title')}
                content={t('ins-content')}
                actions={
                  <Box gap="SP2" direction="vertical">
                    <Box gap="SP2">
                      <Button
                        suffixIcon={<Icons.Edit />}
                        as="a"
                        href={`https://www.wix.com/editor/${instanceData?.site?.siteId}`}
                        target="_blank"
                      >
                        {t('ins-edit-button')}
                      </Button>
                      <Button
                        suffixIcon={<Icons.PremiumFilled />}
                        skin={isUpgraded ? "premium-light" : "premium"}
                        as="a"
                        href={upgradeUrl}
                        target="_blank"
                      >
                        {isUpgraded ? t('manage-plan-button') : t('upgrade-live-button')}
                      </Button>
                    </Box>
                    {connection?.isConnected && (
                      <SectionHelper
                        title={
                          connection?.isConnected
                            ? t('connected_to', {
                              fullName: connection?.meta?.data?.full_name,
                              username: connection?.meta?.data?.username
                            })
                            : t('not-Connected')
                        }
                        appearance="success"
                      >
                        <Box gap="SP2" direction="vertical">
                          {isUpgraded
                            ? t('follower-count-msg')
                            : t('follower-upgrade-msg')}
                          {isRequestedRefresh ? (
                            <Button disabled>You've requested a refresh</Button>
                          ) : (
                            <Button
                              disabled={!isUpgraded}
                              onClick={() => {
                                if (isUpgraded) {
                                  updateCount();
                                } else {
                                  window.open(upgradeUrl, "_blank");
                                }
                              }}
                            >
                              {t('refresh-button')}
                            </Button>
                          )}
                        </Box>
                      </SectionHelper>
                    )}
                  </Box>
                }
              />
            </Box>
          }
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
